<template>
  <div>
    <v-skeleton-loader v-if="!verifications" :loading="!verifications" type="table-tbody"></v-skeleton-loader>

    <v-data-table
      v-if="verifications"
      :headers="headers"
      :items="verifications"
      :loading="!verifications"
      class="elevation-1"
      :footer-props="{ itemsPerPageOptions: [50, 100, 500] }"
      :options.sync="opts"
      :server-items-length="total"
      :show-expand="role !== 'coordinator'"
      :expanded.sync="expanded"
      single-expand
      v-model="selected"
      :show-select="displayEdit"
      sort-by="created_at"
      sort-desc
      :item-class="itemRowBackground"
      @click:row="redirectToEdit"
    >
      <template #[`item.type`]="{ item }">
        {{ item.type ? item.type.name : undefined }} / {{ item.job_type ? item.job_type.name : undefined }}
      </template>

      <template v-slot:item.meter_status_id="{ item }">
        {{ getMeterStatus(item.meter_status_id) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        <span v-if="role === 'coordinator'">
          {{ item.created_at.substr(0, 10) }}
        </span>
        <span v-else>
          {{ new Date(item.created_at).toLocaleString("ru-RU") }}
        </span>
      </template>

      <template v-slot:item.verification_date="{ item }">
        {{ item.verification_date ? new Date(item.verification_date).toLocaleString('ru-RU').substring(0, 10) : '-' }}
      </template>

      <template v-slot:item.route_id="{ item }">
        {{ item.route ? item.route.name : '-' }}
      </template>

      <template v-slot:item.meter_type="{ item }">
        {{ meterBrand(item) }}
      </template>

      <template v-slot:item.address="{ item }">
        <div @click.stop="e => e.stopPropagation()">
          {{ item.address }}

          <v-btn
            icon
            color="primary"
            @click.stop="copyAddress(item.address)"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.phone="{ item }">
        <a
          v-if="item.phone"
          :href="`tel:${item.phone}`"
          @click.stop="e => e.stopPropagation()"
        >
          {{ item.phone }}
        </a>

        <a
          v-if="item.phone_1"
          :href="`tel:${item.phone_1}`"
          @click.stop="e => e.stopPropagation()"
        >
          {{ item.phone_1 }}
        </a>

        <a
          v-if="item.phone_2"
          :href="`tel:${item.phone_2}`"
          @click.stop="e => e.stopPropagation()"
        >
          {{ item.phone_2 }}
        </a>
      </template>

      <template v-slot:item.manager_id="{ item }">
        {{ item.manager ? item.manager.name : '-' }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="role !== 'coordinator'"
          color="green lighten-1"
          class="mr-2"
          :href="`${host}/verification/${item.id}/images`" target="_blank"
          icon
          @click.stop="e => e.stopPropagation()"
        >
          <v-icon>
            mdi-download
          </v-icon>
        </v-btn>

        <!-- v-if="role !== 'coordinator'" -->
        <v-btn
          color="green lighten-1"
          class="mr-2"
          icon
          @click.stop="showHistory(item.id)"
        >
          <v-icon>
            mdi-history
          </v-icon>
        </v-btn>

        <v-btn
          v-if="role === 'coordinator' && item.meter_status_id !== 9"
          class="mr-2"
          @click.prevent="showCommentDialog(item.id, item.coordinator_comment)"
          icon
        >
          <v-icon>mdi-comment</v-icon>
        </v-btn>

        <v-btn
          v-if="role === 'coordinator' && item.meter_status_id !== 9"
          color="green lighten-1"
          class="mr-2"
          @click.prevent="approve(item.id)"
          icon
        >
          <v-icon>mdi-checkbox-marked</v-icon>
        </v-btn>

        <v-btn
          v-if="role === 'coordinator' && item.meter_status_id === 7"
          color="red lighten-1"
          class="mr-2"
          @click.prevent="showDeclineDialog(item.id, item.coordinator_comment)"
          icon
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
        >
          <v-simple-table class="list__table mb-4">
            <template v-slot:default>
              <tbody>
              <tr>
                <td>Статус заказчика</td>
                <td>{{ item.client_status ? item.client_status.name : undefined }}</td>
              </tr>

              <tr>
                <td>Поверитель</td>
                <td>{{ item.verifier ? item.verifier.name : undefined }}</td>
              </tr>

              <tr>
                <td>Слесарь</td>
                <td>{{ item.mechanic ? item.mechanic.name : undefined }}</td>
              </tr>

              <tr>
                <td>Следующая поверка</td>
                <td>{{ item.check_date | date }}</td>
              </tr>

              <tr v-if="item.type_id === 1">
                <td>Марка счётчика</td>
                <td>{{ item.gas_meter ? item.gas_meter.brand : undefined }}</td>
              </tr>

              <tr>
                <td>Заводской номер</td>
                <td>
                  {{
                    item.type_id === 1 ?
                      (item.gas_meter ? item.gas_meter.factory_number : undefined) :
                      (item.water_meter ? item.water_meter.factory_number : undefined)
                  }}
                </td>
              </tr>

              <tr v-if="item.type_id === 1">
                <td>Расположение</td>
                <td>{{ item.gas_meter && item.gas_meter.install_place ? item.gas_meter.install_place.name : undefined }}</td>
              </tr>

              <tr
                v-if="displayEdit"
              >
                <td>Комментарий</td>
                <td>{{ item.comment }}</td>
              </tr>

              <tr
                v-if="item.phone_1 || item.phone_2"
              >
                <td>Дополнительные телефоны</td>
                <td>{{ item.phone_1 }}{{ item.phone_2 ? `, ${item.phone_2}` : '' }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            v-if="item.report || item.meter_status_id !== 3"
            class="mb-8"
          >
            <v-btn
              v-if="item.report"
              :href="getReportFullPath(item.report)"
              class="mr-2"
              target="_blank"
            >
              Посмотреть акт
            </v-btn>

            <v-btn
              v-if="item.meter_status_id !== 3 && displayEdit"
              @click.stop="$emit('delete', item.id)"
              color="error"
              outlined
            >
              Удалить
            </v-btn>
          </div>

          <div
            v-if="item.images.length > 0"
            class="d-flex images"
          >
            <v-img
              v-for="image in item.images"
              :key="image.id"
              :src="getImageFullPath(image.path)"
              class="images__item"
              @click="displayImage(image)"
            />
          </div>
        </td>
      </template>
    </v-data-table>

    <v-overlay
      class="overlay"
      :value="popupImage !== null"
    >
      <v-img
        class="overlay__image"
        :src="popupImage"
        contain
        :style="`transform: rotate(${rotate}deg)`"
      />

      <div class="overlay__button-wrapper">
        <v-btn
          icon
          @click="rotateImage(-90)"
        >
          <v-icon>mdi-file-rotate-left</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="rotateImage(90)"
        >
          <v-icon>mdi-file-rotate-right</v-icon>
        </v-btn>

        <v-btn
          class="overlay__button"
          @click="popupImage = null"
        >
          Закрыть
        </v-btn>
      </div>
    </v-overlay>

    <v-dialog
      v-if="displayEdit"
      v-model="showRouteDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
        <span class="headline">
          Маршрут
        </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  :items="routes.filter(item => { return !Date.parse(item.date) || Date.parse(item.date) > Date.now() || (editing_verification && item.id === editing_verification.route_id) }).map(item => ({ value: item.id, text: item.name }))"
                  label="Маршрутный лист"
                  v-model="editing_route_id"
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="showRouteDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            outlined
            color="blue"
            @click="onSubmitRoute"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="commentDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            Добавление комментария
          </span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            label="Комментарий"
            v-model="comment"
            clearable
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="commentDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            outlined
            color="blue"
            @click="addCoordinatorComment"
            :disabled="!comment"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="declineDialog"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            Отклонение заявки
          </span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="declineReason"
            :items="declineReasons"
            label="Выберите причину"
            @change="comment = declineReason"
          />

          <v-textarea
            label="Комментарий"
            v-model="comment"
            clearable
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="declineDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            outlined
            color="red"
            @click="decline"
            :disabled="!comment"
          >
            Отклонить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="historyDialog"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            История
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="historyHeaders"
                  :items="history"
                >
                  <template v-slot:item.created_at="{ item }">
                    {{ new Date(item.created_at).toLocaleString('ru-RU') }}
                  </template>
                  <template v-slot:item.user="{ item }">
                    {{ item.user ? item.user.name : '-' }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="historyDialog = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HOST} from '@/config'
import { ListsService, VerificationsService } from '@/common/api.service'
// import RoutesPopup from '@/components/Routes/Popup'

export default {
  name: 'Verifications',

  props: {
    verifications: {
      required: true
    },
    options: {
      required: true
    },
    total: {
      required: true
    },
    displayEdit: {
      type: Boolean,
      default: true
    },
    routes: {
      required: false
    },
    selectedParent: {
      required: false
    }
  },

  components: {
    // RoutesPopup
  },

  data () {
    return {
      date: null,
      datepickerOpened: false,
      isLoaded: false,
      popupImage: null,
      meter_statuses: [],
      host: HOST,

      // Выбранные чекбоксами поверки
      selected: [],

      opts: {
        sortBy: ['created_at'],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },

      editing_route_id: null,
      editing_verification: null,
      showRouteDialog: false,
      commentDialog: false,
      declineDialog: false,
      editingId: null,
      comment: '',
      declineReason: '',
      declineReasons: [
        'Необходимо заключить договор',
        'Необходимо перезаключить договор для актуализации данных (ТО проводилось более 12 месяцев назад)',
        'Необходимо перезаключить договор для актуализации данных (сменился собственник)',
        'Договор есть, необходимо провести плановый ТО',
        'Замена счётчика в местном подразделении Горгаз'
      ],
      expanded: [],

      // История
      history: [],
      historyLoading: false,
      historyDialog: false,
      historyHeaders: [
        {
          text: 'Дата',
          value: 'created_at',
          align: 'left',
          sortable: false
        },
        {
          text: 'Статус',
          value: 'status',
          sortable: false
        },
        {
          text: 'Изменения',
          value: 'changes',
          sortable: false
        },
        {
          text: 'Пользователь',
          value: 'user',
          sortable: false
        },
        {
          text: 'Комментарий',
          value: 'comment',
          sortable: false
        },
        {
          text: 'Файлы',
          value: 'images',
          sortable: false
        }
      ],
      rotate: 0,
      selectedImage: null
    }
  },

  methods: {
    // Копирование адреса
    copyAddress (address) {
      navigator.clipboard.writeText(address)
    },

    //
    redirectToEdit (value) {
      if (!this.displayEdit || !value || !value.id) {
        return
      }

      this.edit(value.id)
    },

    edit (id) {
      this.$router.push(`/${this.role}/verifications/edit/${id}`)
    },

    meterBrand (verification) {
      if (verification.new_gas_meter) {
        return verification.new_gas_meter.brand
      }

      if (verification.gas_meter) {
        return verification.gas_meter.brand
      }

      if (verification.new_water_meter) {
        return verification.new_water_meter.type
      }

      if (verification.water_meter) {
        return verification.water_meter.type
      }

      return ''
    },

    approve (id) {
      this.updateStatus(id,9)
    },

    decline () {
      VerificationsService
        .addCoordinatorComment(this.editingId, this.comment)

      this.declineDialog = false
      this.updateStatus(this.editingId, 8)
    },

    updateStatus (id, status) {
      VerificationsService
        .updateStatus(id, status)
        .then(() => {
          this.$notification('Успешно сохранено')
          this.$emit('updated', this.opts)
        })
        .catch(() => {
          this.$notification('Ошибка сохранения')
        })
    },

    showCommentDialog (id, comment) {
      this.editingId = id
      this.comment = comment
      this.commentDialog = true
    },

    // История изменения заявки
    showHistory (id) {
      this.history = []
      this.historyLoading = true
      this.historyDialog = true

      VerificationsService
        .getHistory(id)
        .then((response) => {
          this.history = response.history
        })
        .catch((e) => {
          console.log(e)
          this.$notification('Ошибка получения истории')
        })
        .finally(() => {
          this.historyLoading = false
        })
    },

    showDeclineDialog (id, comment) {
      this.editingId = id
      this.comment = comment
      this.declineDialog = true
    },

    addCoordinatorComment () {
      VerificationsService
        .addCoordinatorComment(this.editingId, this.comment)
        .then(() => {
          this.$notification('Успешно сохранено')
          this.commentDialog = false
          this.comment = ''
          this.$emit('updated', this.opts)
        })
        .catch(() => {
          this.$notification('Ошибка сохранения')
        })
    },

    displayImage (image) {
      this.rotate = 0
      this.selectedImage = image
      this.popupImage = this.getImageFullPath(image.path)
    },

    getImageFullPath (path) {
      return `${this.host}/storage/images/${path}?v=${Math.random()}`
    },

    getReportFullPath (report) {
      return `${this.host}/storage/files/${report}`
    },

    getMeterStatus (id) {
      const status = this.meter_statuses.filter(s => s.id === id)
      return status.length ? status[0].name : '—'
    },

    // Классы строк таблицы в зависимости от статуса поверки
    itemRowBackground (item) {
      // Для воды голубой, если поверка не завершена
      if (item.type_id === 2 && item.meter_status_id !== 3) {
        return 'blue lighten-4'
      }

      switch (item.meter_status_id) {
        case 1: return ''
        case 2: return 'orange lighten-4'
        case 3: return 'green lighten-4'
        case 4: return 'grey lighten-4'
        case 5: return 'red lighten-4'
        case 6: return 'pink lighten-4'
        case 9: return this.role === 'coordinator' ? 'white' : 'red lighten-4'
      }
    },

    onSubmitRoute () {
      VerificationsService
        .updateRoute(this.editing_verification.id, this.editing_route_id)
        .then(() => {
          this.editing_verification = null
          this.editing_route_id = null
          this.showRouteDialog = false

          this.$notification('Успешно сохранено')

          this.$emit('updated', this.opts)
        })
        .catch(() => {
          this.$notification('Ошибка сохранения')
        })
    },

    rotateImage (angle) {
      this.rotate += angle

      VerificationsService.rotateImage(this.selectedImage.id, angle)
    }
  },

  computed: {
    role () {
      return this.$store.getters.user.role
    },

    // Заголовки таблицы
    headers () {
      const result = []

      result.push({
        text: 'Дата поверки',
        value: 'verification_date',
        width: '156px',
        align: 'left'
      })

      result.push({
        text: 'Дата создания',
        value: 'created_at',
        width: '164px'
      })

      if (this.displayEdit) {
        result.push({
          text: 'Маршрут',
          value: 'route_id',
          width: '130px'
          // sortable: false
        })
      }

      result.push({
        text: 'Тип',
        value: 'type'
      })

      result.push({
        text: 'Статус',
        value: 'meter_status_id'
      })

      result.push({
        text: 'Адрес',
        value: 'address',
        // sortable: false
      })

      result.push({
        text: 'Счётчик',
        value: 'meter_type',
        sortable: false
      })

      if (!this.displayEdit) {
        result.push({
          text: 'Имя',
          value: 'name'
        })
      }

      result.push({
        text: 'Телефон',
        value: 'phone',
        sortable: false
      })

      if (this.displayEdit) {
        result.push({
          text: 'Комментарий',
          value: 'comment',
          sortable: false
        })
      }

      result.push({
        text: 'Комментарий согласовывающего',
        value: 'coordinator_comment',
        sortable: false
      })

      if (this.role !== 'coordinator' && this.role !== 'company') {
        result.push({
          text: 'Цена',
          value: 'price',
          sortable: false
        })

        result.push({
          text: 'Автор',
          value: 'manager_id',
          sortable: false
        })
      }

      result.push({
        text: 'Действия',
        value: 'actions',
        align: 'right',
        sortable: false
      })

      result.push({
        text: '',
        value: 'data-table-expand',
        sortable: false
      })

      return result
    }
  },

  watch: {
    selected: {
      handler (value) {
        this.$emit('selectChanged', value.map(a => a.id))
      }
    },

    selectedParent: {
      handler (value) {
        if (value.length === 0 && this.selected.length !== 0) {
          this.selected = []
        }
      }
    },

    verifications: {
      handler () {
        // this.selected = []
      }
    },

    opts: {
      handler (value, oldValue) {
        if (value.page === oldValue.page) {
          this.selected = []
        }

        this.$emit('update', value)
      }
    },

    options: {
      handler () {
        this.opts.sortBy = this.options.sortBy
        this.opts.sortDesc = this.options.sortDesc
        this.opts.itemsPerPage = this.options.itemsPerPage
        this.opts.page = this.options.page
      },

      deep: true
    }
  },

  async mounted() {
    this.opts.sortBy = this.options.sortBy
    this.opts.sortDesc = this.options.sortDesc
    this.opts.itemsPerPage = this.options.itemsPerPage
    this.opts.page = this.options.page

    ListsService
      .get(['meter_statuses'])
      .then(({data}) => {
        this.meter_statuses = data.meter_statuses
        this.isLoaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
.list {
  align-items: center;

  &__table {
    max-width: 50%;
  }
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  min-height: 250px;

  &__item {
    box-sizing: border-box;
    max-width: 200px;
    margin-right: 16px;
    margin-bottom: 16px;
    min-height: 100px;
  }
}

.overlay {
  &__image {
    max-width: 80vw;
    max-height: 80vh;
    margin-bottom: 16px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: 0 auto;
  }
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
